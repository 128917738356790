import React, { memo } from "react";
import { download } from "./server";
import { Form, DatePicker, Button, Input } from "antd";

export default memo(function index() {
  const handleDownload = async ({ team, time }) => {
    const { path, secret } = await download(team, time);
    window.open(`/api/download?path=${path}&secret=${secret}`);
  };

  return (
    <Form onFinish={handleDownload}>
      <Form.Item name="team" label="TEAM">
        <Input placeholder="请输入团队值 不填默认所有团队" />
      </Form.Item>
      <Form.Item name="time" label="时间区间">
        <DatePicker.RangePicker />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">下载</Button>
      </Form.Item>
    </Form>
  );
});
