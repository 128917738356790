import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Form,
  Input,
  List,
  message,
  Modal,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import BaseView from '../../components/BaseView';
import Topic from '../../components/Topic';
import Tkt from '../../components/Tkt';
import moment from 'moment';

const name = 'toluna';
function Toluna() {
  const [surveyCountList, setSurveuCountList] = useState([]);
  const [surveyList, setSurveyList] = useState([]);
  const [config, setConfig] = useState(null);
  const [type, setType] = useState('');

  const [saveData, setSaveData] = useState({
    current: 1,
    pageSize: 15,
    list: [],
    total: 0,
  });

  const [ipInfo, setIpInfo] = useState({});

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(async () => {
    axios.get(`${name}/getConfig`).then((res) => {
      setConfig(res);
      console.log(
        type &&
          config &&
          Object.keys(config[type]['form']).map((item) => {
            const obj = config[type]['form'][item];
            return Object.keys(obj.answers).map((item) => ({
              label: obj.answers[item],
              value: item,
            }));
          })
      );
    });

    try {
      handleSaveList({
        current: 1,
        pageSize: 15,
      });
      const { form1: _form1, form2: _form2 } = JSON.parse(
        localStorage.getItem(`${name}_form`)
      );
      const list = JSON.parse(localStorage.getItem(`${name}_list`));
      setSurveyList([...(list || [])]);
      if (_form1.type) {
        setType(_form1.type);
      }
      form2.setFieldsValue(_form2);
      form1.setFieldsValue(_form1);
    } catch (e) {}
    /* handlegetSurveyCount(); */
    const timer = setInterval(() => {
      handleSaveForm();
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleSaveForm = () => {
    localStorage.setItem(
      `${name}_form`,
      JSON.stringify({
        form1: form1.getFieldsValue(),
        form2: form2.getFieldsValue(),
      })
    );

    console.log('已保存');
  };

  const handlegetSurveyCount = () => {
    axios.get(`${name}/getSurveyCount`).then((res) => {
      setSurveuCountList(res);
    });
  };
  const handleLink = (e) => {
    axios
      .get(`${name}/getLink`, { params: { ...e } })
      .then((res) => {
        const { username, list } = res;
        form2.setFieldsValue({
          name: username,
        });
        form1.setFieldsValue({
          username: username,
        });
        setSurveyList([...list]);
        handleSaveForm();
        localStorage.setItem(`${name}_list`, JSON.stringify(list));
        message.success('获取成功');
      })
      .catch((e) => {});
  };

  const handleSave = (e) => {
    axios.post(`${name}/save`, e).then((res) => {
      handleSaveForm();
      message.success('提交成功');
    });
  };

  const handleSaveList = (e) => {
    axios
      .get(`${name}/getSaveList`, { params: { ...e } })
      .then((res) => {
        const { current_page, data, total, per_page } = res;
        setSaveData(() => ({
          current: current_page,
          list: data,
          total: total,
          pageSize: per_page,
        }));
      })
      .catch((e) => {});
  };

  const handleSaveDel = (e) => {
    axios
      .get(`${name}/saveDel`, { params: { ...e } })
      .then(() => {
        message.success('删除成功');
        handleSaveList({
          current: 1,
          pageSize: 15,
        });
      })
      .catch((e) => {});
  };

  const handleSaveUpdate = (e) => {
    axios
      .get(`${name}/saveUpdate`, { params: { ...e } })
      .then(() => {})
      .catch((e) => {});
  };

  return (
    <BaseView name={name}>
      {/* <Card title='问卷次数排行'>
        <List
          style={{ height: '158px', overflowY: 'scroll' }}
          dataSource={surveyCountList}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta title={`${index + 1}.${item.name}`} />
              <div>{item.num}次</div>
            </List.Item>
          )}
        />
      </Card> */}
      <Card
        title='操作'
        extra={
          <Space>
            <Button
              onClick={() => {
                axios
                  .get(`${name}/getIp`)
                  .then((res) => {
                    message.success(res);
                  })
                  .then(() => {
                    /* fetch('https://ipinfo.io/json?token=7bfff1ab831355')
                      .then((response) => response.json())
                      .then((jsonResponse) => {
                        const { postal } = jsonResponse;
                        setIpInfo(jsonResponse);
                        form1.setFieldsValue({
                          postal: postal,
                        });
                      }); */
                  });
              }}
            >
              检测IP
            </Button>
          </Space>
        }
      >
        {/* <Tkt /> */}
        <Form
          form={form1}
          onFinish={(e) => {
            handleLink({
              ...e,
              birthday: moment(e.birthday, 'YYYY-MM-DD').format('MM/DD/YYYY'),
            });
          }}
          onValuesChange={(e) => {
            if (e.type) {
              setType(e.type);
            }
          }}
        >
          <Form.Item name='type' label='项目' required>
            <Select
              options={[
                { label: '请选择项目', value: '-' },
                ...Object.keys(config || {}).map((item) => ({
                  label: config[item].name,
                  value: item,
                })),
              ]}
            />
          </Form.Item>
          <Form.Item name='username' label='账号' required>
            <Input />
          </Form.Item>
          <Form.Item name='birthday' label='生日' required>
            <Input placeholder='年年年年-月月-日日 1975-02-08' />
          </Form.Item>
          <Form.Item name='age' label='年龄' required>
            <Input />
          </Form.Item>
          {type &&
            config &&
            Object.keys(config[type]['form']).map((item) => {
              const obj = config[type]['form'][item];
              return (
                <Form.Item
                  key={item}
                  name={item}
                  label={obj.question.name}
                  required
                >
                  <Select
                    options={Object.keys(obj.answers).map((item) => ({
                      label: obj.answers[item],
                      value: item,
                    }))}
                  />
                </Form.Item>
              );
            })}
          <Form.Item name='postal' label='邮编' required>
            <Input />
          </Form.Item>
          <Form.Item name='region' label='归属地' required>
            <Input placeholder='' />
          </Form.Item>
          <Form.Item>
            <Button htmlType='submit' type='primary'>
              获取问卷
            </Button>
            {/* <Button
              type='primary'
              style={{ marginLeft: '8px' }}
              onClick={() => {
                axios
                  .get(`${name}/getRandomLink`, {
                    params: { ...form1.getFieldsValue() },
                  })
                  .then((res) => {
                    const { form, list } = res;
                    form1.setFieldsValue(form);
                    setSurveyList([...list]);
                    handleSaveForm();
                    localStorage.setItem(`${name}_list`, JSON.stringify(list));
                    message.success('获取成功');
                  })
                  .catch((e) => {});
              }}
            >
              随机获取
            </Button> */}
          </Form.Item>
          <Form.Item></Form.Item>
        </Form>
        <Table
          rowKey='SurveyID'
          dataSource={surveyList}
          columns={[
            {
              dataIndex: 'SurveyID',
              title: '问卷ID',
            },
            {
              dataIndex: 'Name',
              title: '问卷名',
            },
            {
              dataIndex: 'IR',
              title: '转换比例',
            },
            {
              dataIndex: 'Duration',
              title: '时间长度',
            },
            {
              dataIndex: 'PartnerAmount',
              title: '积分',
            },
            {
              dataIndex: 'action',
              title: '操作',
              render: (_, record) => (
                <Space>
                  <Button
                    type='link'
                    onClick={() => {
                      copy(record.URL);
                    }}
                  >
                    复制链接
                  </Button>
                  <Button
                    type='link'
                    onClick={() => {
                      window.open(record.URL);
                    }}
                  >
                    打开链接
                  </Button>
                </Space>
              ),
            },
          ]}
          pagination={{
            pageSize: 15,
          }}
        ></Table>
        {/* <Form
          form={form2}
          onFinish={(e) => {
            handleSave(e);
          }}
        > */}
        {/* <Form.Item name='name' label='账号'>
            <Input readOnly required />
          </Form.Item> */}
        {/* <Form.Item name='url' label='链接'>
            <Input required />
          </Form.Item> */}
        {/* <Form.Item name='region' label='归属地'>
            <Input
              required
              placeholder='请输入 美国  /  NJ  /  Cliffside Park'
            />
          </Form.Item> */}
        {/* <Form.Item name='sex' label='性别'>
            <Select>
              <Select.Option value={0}>男</Select.Option>
              <Select.Option value={1}>女</Select.Option>
            </Select>
          </Form.Item> */}
        {/* <Form.Item name='age' label='年龄'>
            <Input required />
          </Form.Item> */}
        {/* <Form.Item>
            <Space>
              <Button type='primary' htmlType='submit'>
                保存数据
              </Button>
            </Space>
          </Form.Item> */}
        {/* </Form> */}
        {/* <Table
          rowKey='id'
          columns={[
            { dataIndex: 'name', title: '账号' },
            {
              dataIndex: 'sex',
              title: '性别',
              render: (value) => (value == '1' ? '女' : '男'),
            },
            { dataIndex: 'age', title: '年龄' },
            { dataIndex: 'country', title: '国家' },
            {
              dataIndex: 'province',
              title: '州',
              render: (value) => (
                <div
                  onClick={() => {
                    copy(value.replace(' ', ''));
                  }}
                >
                  {value}
                </div>
              ),
            },
            {
              dataIndex: 'city',
              title: '市',
              render: (value) => (
                <div
                  onClick={() => {
                    copy(value.replace(' ', ''));
                  }}
                >
                  {value}
                </div>
              ),
            },
            { dataIndex: 'itime', title: '时间' },
            {
              dataIndex: 'action',
              title: '操作',
              render: (_, record) => (
                <Space>
                  <Button
                    type='link'
                    onClick={() => {
                      copy(record.url);
                    }}
                  >
                    复制链接
                  </Button>
                  <Button
                    type='link'
                    onClick={() => {
                      handleSaveUpdate({
                        id: record.id,
                      });
                      window.open(record.url);
                    }}
                  >
                    打开链接
                  </Button>
                  <Button
                    type='link'
                    danger
                    onClick={() => {
                      handleSaveDel({
                        id: record.id,
                      });
                    }}
                  >
                    删除
                  </Button>
                </Space>
              ),
            },
          ]}
          pagination={{
            current: saveData.current,
            total: saveData.total,
            pageSize: saveData.pageSize,
            onChange: (page, pageSize) => {
              handleSaveList({ current: page, pageSize });
            },
          }}
          dataSource={saveData.list}
        /> */}
      </Card>
      {/* <Topic name={name} /> */}
    </BaseView>
  );
}
export default Toluna;
