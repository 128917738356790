import axios from "axios";
import moment from "moment";

export const download = (team, time) =>
  axios.get("/fanyi/download/getCsv", {
    params: {
      team,
      ...(time && {
        startTime: moment(time[0]).format("YYYY-MM-DD"),
        endTime: moment(time[1]).format("YYYY-MM-DD"),
      }),
    },
  });
