import React, { memo } from "react";
import { Tabs } from "antd";
import FanyiReport from "./FanyiReport";
import Record from "./Record";

const { TabPane } = Tabs;

export default memo(function index() {
  return (
    <div style={{ backgroundColor: "#fff", padding: "24px" }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="翻译报表" key="1">
          <FanyiReport />
        </TabPane>
        <TabPane tab="记录下载" key="2">
          <Record />
        </TabPane>
      </Tabs>
    </div>
  );
});
